import React, { useEffect, useState, useRef } from "react";
import SyncLoader from "react-spinners/SyncLoader";
import { TZ_URL, tokenVerify, useStreamedData } from "./api";
import Markdown from "react-markdown";
import { useMutation } from "react-query";
import { ClipLoader } from "react-spinners";

function App() {
  const [messageToSend, setMessageToSend] = useState("");
  const [user, setUser] = useState(null);
  const [menuIsOpen, setOpenMenu] = useState(false);
  const [loadedChat, setLoadedChat] = useState([]);
  const [previousChats, setPreviousChats] = useState(
    JSON.parse(localStorage.getItem("previousChats") || "[]"),
  );
  const { getStream, getThread, data, isLoading } = useStreamedData();
  const textareaRef = useRef(null);
  const messagesEndRef = useRef(null);

  const sendMessage = ({ message }) => {
    let threadId = data.threadId || loadedChat?.messages?.at(-1).threadId;
    getStream({ message, thread_id: threadId });

    setMessageToSend("");
  };

  useEffect(() => {
    if (data.messages) {
      const newPreviousChats = [
        ...previousChats.filter(({ threadId }) => threadId !== data.threadId),
        {
          threadId: data.threadId,
          key: data.messages.at(-1).message,
        },
      ];

      localStorage.setItem("previousChats", JSON.stringify(newPreviousChats));
      setPreviousChats(newPreviousChats);
    }

    // eslint-disable-next-line
  }, [data]);

  const urlParams = new URLSearchParams(window.location.search);
  const token = localStorage.getItem("token") || urlParams.get("token");

  const { mutate: verifyToken, isLoading: isLoadingVerify } = useMutation({
    mutationFn: tokenVerify,
    mutationKey: ["tokenVerify", token],
    onSuccess: ({ user }) => {
      localStorage.setItem("token", token);
      setUser(user);
    },
    onError: () => {
      localStorage.removeItem("token");
      window.location.assign("/");
    },
  });

  useEffect(() => {
    if (token) {
      verifyToken({ token });
    }
  }, [token, verifyToken]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [messageToSend]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [data.messages, loadedChat.messages]);

  const loadChat = async (chat) => {
    const threadData = await getThread(chat.threadId);
    setLoadedChat({ messages: threadData, threadId: chat.threadId });
    setMessageToSend("");
  };

  if (!token) {
    const redirectTo =
      process.env.REACT_APP_REDIRECT_TO || `https://surveyor-ai.pages.dev/`;
    window.location.assign(`${TZ_URL}/?successRedirect=${redirectTo}`);
  }

  if (isLoadingVerify) return <ClipLoader />;

  const deleteChat = (index) => {
    const newPreviousChats = previousChats.filter((_, i) => index !== i);
    setPreviousChats(newPreviousChats);
    localStorage.setItem("previousChats", JSON.stringify(newPreviousChats));
  };

  return (
    <div className="relative bg-gradient-to-br from-[#7F7FD5] via-[#86A8E7] to-[#91EAE4]">
      <div
        className={`
        bg-white/25 absolute w-full md:text-2xl z-40 backdrop-blur-lg flex justify-between items-center
        shadow
      `}
      >
        <div className="flex">
          {menuIsOpen ? (
            <button
              className="p-4 md:hidden"
              onClick={() => setOpenMenu(false)}
            >
              <span className="text-white material-symbols-rounded">close</span>
            </button>
          ) : (
            <button className="p-4 md:hidden" onClick={() => setOpenMenu(true)}>
              <span className="text-white material-symbols-rounded">menu</span>
            </button>
          )}
          <h2 className="text-white font-bold p-4 pl-0 md:pl-4">Survai</h2>
        </div>
        <p className="text-sm text-white p-4">{user?.email}</p>
      </div>

      <div className="h-dvh flex">
        <nav
          className={`bg-white/70 backdrop-blur-lg w-11/12 max-w-96 border-r border-gray-300 ${menuIsOpen ? "left-0" : "-left-full"
            } shadow-lg md:left-0 absolute md:relative transition-all z-50 md:z-30 rounded-3xl mt-20 m-4`}
        >
          <div className="p-8">
            <h3 className="font-semibold mb-4">Previous Questions</h3>
            <ul>
              {previousChats.map((chat, index) => (
                <li
                  key={index}
                  className="cursor-pointer hover:bg-black/30 hover:text-white rounded-full px-2 flex justify-between items-center"
                  onClick={() => loadChat(chat)}
                >
                  <span>{chat.key.slice(0, 30)}...</span>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteChat(index);
                    }}
                    className="rounded-full hover:bg-black/70 h-6 relative -right-2"
                  >
                    <span className="material-symbols-rounded">close</span>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </nav>
        <div className="grow flex flex-col">
          <div className="grow flex flex-col overflow-y-scroll justify-end space-y-2 mb-8 pt-32 md:pt-0">
            <div className="h-full w-full relative">
              <div className="text-white flex flex-col items-center">
                <span className="material-symbols-rounded !text-8xl ">
                  live_help
                </span>
                <span className="md:mb-[30%] max-w-64 mx-8 p-4 inline-block text-center">
                  Ask me something about building regs in England and Wales
                </span>
              </div>

              <div className="flex flex-col">
                {[...(loadedChat?.messages || []), ...(data?.messages || [])]
                  ?.sort((a, b) => a.created_at - b.created_at)
                  .map(({ message, role }, i) => (
                    <div
                      key={i}
                      className={`
                ${i === 0 && "mt-20"}
                ${role === "user"
                          ? "bg-white/50 self-end rounded-tl-xl rounded-bl-xl rounded-tr-xl shadow-xl"
                          : "bg-black/50 text-white shadow-xl self-start rounded-tl-xl rounded-br-xl rounded-tr-xl"
                        } 
                text-info-content p-4 m-4 space-y-4 max-w-96`}
                    >
                      <Markdown
                        components={{
                          p(props) {
                            const { node, ...rest } = props;
                            return <p {...rest} />;
                          },
                        }}
                      >
                        {message}
                      </Markdown>
                    </div>
                  ))}
                <div ref={messagesEndRef} />
              </div>
              {isLoading && <SyncLoader size={10} color="rgba(0,0,0,0.5)" />}
            </div>
          </div>
          <div className="grid grid-cols-12 gap-2 mx-2 md:mx-8 mb-4 -mt-4">
            <textarea
              type="text"
              rows={1}
              placeholder="Type here"
              className="bg-white shadow-xl md:col-span-10 col-span-9 px-4 py-2 border border-gray-300 rounded-3xl"
              onChange={(e) => setMessageToSend(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  sendMessage({ message: messageToSend });
                }
              }}
              value={messageToSend}
              ref={textareaRef}
              style={{ resize: "none", overflow: "hidden" }}
            ></textarea>
            <div className="flex justify-center items-end md:col-span-2 col-span-3">
              <button
                className="btn btn-primary bg-cyan-500/50 border-4 border-cyan-500 shadow-xl w-full h-12 rounded-full"
                onClick={() => {
                  sendMessage({ message: messageToSend });
                }}
              >
                <span className="material-symbols-rounded !text-2xl text-cyan-700">
                  send
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
