import { useState, useEffect, useRef } from "react";

const BASE_URL =
  process.env.REACT_APP_BASE_URL ||
  "https://surveyor-api-dev.fngnhc84m6.workers.dev";
export const TZ_URL = "https://surveyor-ai.trust-zero.uk";

export const tokenVerify = async ({ token }) => {
  const response = await fetch(`${TZ_URL}/token-verify`, {
    method: "POST",
    body: JSON.stringify({ token }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const parsedResponse = await response.json();

  if (!response.ok) {
    throw parsedResponse;
  }

  return parsedResponse;
};

export const useStreamedData = () => {
  const [data, setData] = useState("{}");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const eventSourceRef = useRef(null);

  useEffect(() => {
    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, []);

  const getStream = ({ message, thread_id = "" }) => {
    setIsLoading(true);
    setError(null);

    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }

    const params = new URLSearchParams({ message, thread_id }).toString();
    const url = `${BASE_URL}/api/send-message?${params}`;
    eventSourceRef.current = new EventSource(url);

    eventSourceRef.current.onmessage = (event) => {
      const receivedData = JSON.parse(event.data);
      setData(receivedData);
    };

    eventSourceRef.current.onerror = () => {
      eventSourceRef.current.close();
      setIsLoading(false);
    };

    eventSourceRef.current.onopen = () => {
      console.log("Connection to server opened.");
    };
  };

  const getThread = async (thread_id) => {
    try {
      const response = await fetch(`${BASE_URL}/api/get-thread/${thread_id}`);
      const threadData = await response.json();
      return threadData;
    } catch (err) {
      setError(err);
      return null;
    }
  };

  return {
    data,
    clearData: () => setData(`{}`),
    error,
    isLoading,
    getStream,
    getThread,
  };
};
